import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col, Button } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import FeatureWithImage from '../components/FeatureWithImage'
import HiringSection from '../components/Hiring'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'

import blackHorseStrategiesLogo from '../img/client-logos/BHS-line-logo-small.png';
import anuLogo from '../img/client-logos/ANU_logo_horz.png';
import savageSquidLogo from '../img/client-logos/savage-squid-logo.png';
import lakeHavenLogo from '../img/client-logos/lake-haven-logo.png';
import nationalCollegeLogo from '../img/client-logos/national-college-logo.png';

import modernWebImage from '../img/pages/home/homepage-modern-web.png';
import softwareDevelopmentIcon from '../img/pages/home/software-development.png'
import webAppSolutionsIcon from '../img/pages/home/web-app-solutions.png'
import consultingIcon from '../img/pages/home/consulting-icon.png'
import uiDesign from '../img/pages/home/ui-unique-design.png'

const ClientLogo = styled.div`
  transform: scale(.6,.6); flex: 1; background-repeat: no-repeat; background-position: center center; 
  background-size: contain; height: 50px; font-weight: 400; color: #fff;
`;
const BlogLink = styled(Link)`
  color:#444;
  display:block;
  padding: 0 20px;
  &:hover {
    text-decoration: none;
  }
`;
const BlogTitle = styled.em`
  color: #444;
  font-weight: 600;
  font-style: normal;
  font-size:18px;
`;
const BlogDescription = styled.p`
  color: #666;
`;

const WhatWeDoRow = styled(Row)`
  text-align: center; color: #fff;
`
const WhatWeDoCol = styled(Col)`
  padding: 35px;
`
const WhatWeDoH4 = styled.h4`
  border-bottom: 1px dashed #fff; padding-bottom: 10px; margin-bottom: 25px;
`
const WhatWeDoButton = styled(Link)`
  border: 1px solid white;
  background: transparent;
  color: white;
  margin-top: 30px;
  &:hover {
    border: 2px solid white;
    background: rgba(255, 255, 255, .1);
    color: #fefefe;
  }
`

export default ({data}) => (
  <Layout>
    <Helmet
      title="Custom Software & Web Development | Bishop Software"
      meta={[
        { name: 'description', content: 'Bishop Software offers custom software development, web design, mobile development, software consulting, and user experience services to businesses like yours. Let\'s talk!' },
        { name: 'keywords', content: 'software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
      ]}
    />

    <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #14134e 0, #512d5a 56%, #843b61 81%, #c16858 100%)', color: '#fafafa'}}>
      <Header dropdownGradient='linear-gradient(180deg, #d15e95 0, #c45d50 100%)' />

      <Container className="text-center" style={{paddingTop: '1.875rem'}}>
          <div style={{margin: '55px 0 0 0'}}>
            <h1 style={{color: '#ffc092', fontSize: '40px', fontWeight: 300}}>
              Custom Software Development Services
            </h1>
            <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>Bishop Software provides unmatched software and web development<br />services to organizations all around the world.</h3>
            <div style={{marginTop: '35px', paddingBottom: '35px'}}>
              <Link to="/contact" className="btn btn-primary round">Work With Us</Link>
            </div>
          </div>
        </Container>
        <div style={{justifyContent: 'space-between', flexWrap: 'nowrap', alignItems: 'stretch', color: '#f3f3f3', backgroundImage: 'linear-gradient(-180deg, rgba(255,131,131,.15) 0, rgba(114,15,76,.15) 100%)'}}>
              <Container style={{textAlign: 'center', padding: '1.875rem 0'}}>
                <div style={{fontWeight: 400, fontSize: '14px', lineHeight: '20px', opacity: '.7'}}>
                  Some of our clients:
                </div>
                <div style={{margin: '20px 0', display: 'flex'}}>
                    <ClientLogo style={{backgroundImage: `url(${anuLogo})`}}></ClientLogo>
                    <ClientLogo style={{backgroundImage: `url(${blackHorseStrategiesLogo})`}}></ClientLogo>
                    <ClientLogo style={{backgroundImage: `url(${nationalCollegeLogo})`}}></ClientLogo>
                    <ClientLogo style={{backgroundImage: `url(${lakeHavenLogo})`}}></ClientLogo>
                    <ClientLogo style={{backgroundImage: `url(${savageSquidLogo})`}}></ClientLogo>
                </div>
              </Container>
            </div>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <Container>
        <Row>
          <Col md="6" xs="12">
            <h2>A Software Consultancy Built for Everyone</h2>
            <p style={{color: '#555'}}>
              Bishop Software is a VA-based engineering consultancy, specializing in all things software. From hardware drivers to modern web applications, we can help expand your business and achieve your goals.
            </p>
            <p style={{color: '#555'}}>
            Since 2013, our agile team has worked with companies ranging in size from startups, to large enterprises, to build quality software and level up engineering teams.
            </p>
            <p style={{color: '#555'}}>
            Launching a new Software Product? Need Help with an Existing Project?
            </p>
            <div style={{textAlign: 'center', marginTop: '35px'}}>
              <Link to="/contact" className="btn btn-primary round">Contact Us</Link>
            </div>
          </Col>
          <Col md="6" xs="12" className="d-none d-md-block">

            <img src={modernWebImage} />

          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fefefe 80%, #eee 100%)'}}>
      <Container>
      <FeatureWithImage title="Custom Software Development" imageSrc={softwareDevelopmentIcon}>
      Bishop Software has years of experience in turning our clients’ business ideas into successful web solutions that meet their business requirements and contribute to their strategies. We focus on building apps for logistics, education, business services, financial services, and information technology, but we can build solutions for practically any industry.
        </FeatureWithImage>
        <FeatureWithImage title="Web &amp; Mobile Development" imageSrc={webAppSolutionsIcon} right={true}>
        We use cross-platform frameworks and technologies to create consumer-centric business applications for different industries. We build HTML5 hybrid mobile apps and mobile-optimized web apps that provide a native user experience, as well as native apps on ios 
        </FeatureWithImage>
      <FeatureWithImage title="Software Consultancy" imageSrc={consultingIcon}>
      Our business analysts, technical architects, and quality assurance and systems engineers have extensive experience working across different industries and projects. We’ve taken on the toughest software challenges from some of the most visionary and progressive organisations out there. You can rest assured that we’ll get to the bottom of your requirements. And then define a solution that delivers.
        </FeatureWithImage>
        <FeatureWithImage title="UX / UI Development &amp; Design" imageSrc={uiDesign} right={true}>
        Applying the science of User Centred Design (or UCD), we craft every aspect of your site or application around the specific needs, motivations, expectations, behaviours and constraints of your users. We question, we test, and we trial - using both qualitative and quantitative research to ensure that our decisions and recommendations are based on real-world requirements - not assumptions - to give you maximum confidence in the results.
        </FeatureWithImage>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container className="text-center">
        <h2 style={{color: '#fff'}}>Work with us to build your dream application</h2>
        <p>We provide our clients with cost-effective top-notch applications using cutting-edge technologies. Our highly dedicated team ensures on-time delivery of successful solutions tailored to the specific needs of our clients from startups to large enterprises.</p>
        <div style={{marginTop: '40px'}}>
          <Link to="/contact" className="btn btn-primary round">Contact Us</Link>
        </div>
      </Container>
    </section>

    <section style={{padding: '70px 0'}}>
      <Container>
        <Row style={{marginBottom: '20px'}}>
          <div className="text-center" style={{width:"100%"}}>
            <h2>From the Blog</h2>
          </div>
        </Row>
        <Row>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Col lg="4" md="12" key={node.id}>
              <BlogLink to={'/blog' + node.fields.slug}>
                {node.frontmatter.image && <img src={node.frontmatter.image.publicURL} />}
                <BlogTitle>{node.frontmatter.title}</BlogTitle>
                <BlogDescription>{node.excerpt}</BlogDescription>
              </BlogLink>
            </Col>
          ))}
        </Row>
        <Row>
          <Col className="text-center">
            <Link to="/blog" className="btn btn-primary">View All Posts</Link>
          </Col>
        </Row>
      </Container>
    </section>

    <HiringSection />

      <Footer />
  </Layout>
)

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(limit: 3, sort: {fields: [frontmatter___date], order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            image {
              publicURL
            }
          }
          fields {
            slug
          }
          html
          excerpt
        }
      }
    }
  }
`